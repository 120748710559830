//import { createPortal } from "react-dom";

export function getCurrentDateTime(){
    let now = new Date();
    return now;
};
export function getCurrentTime() {
    var now = new Date();
      var utcString = now.toISOString().substring(0,19);
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      var localDatetime = year + "-" +
      (month < 10 ? "0" + month.toString() : month) + "-" +
      (day < 10 ? "0" + day.toString() : day) + "T" +
      (hour < 10 ? "0" + hour.toString() : hour) + ":" +
      (minute < 10 ? "0" + minute.toString() : minute) +
      utcString.substring(16,19);
      //var datetimeField = document.getElementById("myDatetimeField");
      //datetimeField.value = localDatetime;
    return localDatetime;
  }
  export function getTimeString(thisDate) {
    //var now = new Date();
      var utcString = thisDate.toISOString().substring(0,19);
      var year = thisDate.getFullYear();
      var month = thisDate.getMonth() + 1;
      var day = thisDate.getDate();
      var hour = thisDate.getHours();
      var minute = thisDate.getMinutes();
      var second = thisDate.getSeconds();
      var localDatetime = year + "-" +
      (month < 10 ? "0" + month.toString() : month) + "-" +
      (day < 10 ? "0" + day.toString() : day) + "T" +
      (hour < 10 ? "0" + hour.toString() : hour) + ":" +
      (minute < 10 ? "0" + minute.toString() : minute) +
      utcString.substring(16,19);
      //var datetimeField = document.getElementById("myDatetimeField");
      //datetimeField.value = localDatetime;
    return localDatetime;
  }
  
	/**
	 * Add minutes to a date
	 * @param {Date}    date The date object
	 * @param {Integer} n    The number of minutes to add
	 */

	export function addMinutes(myDate, minutes) {
		return new Date(myDate.getTime() + minutes*60000);
	}

	export function addMinutesNumber (date, n) {
        console.log('TimeFunctions - addMinutes');
        console.log('date: ' + date);
        console.log(typeof date);
        console.log('n: ' + n);
        console.log(typeof n);

		return date.setMinutes(date.getMinutes() + n);
	}

    /**
	 * Add seconds to a date
	 * @param {Date}    date The date object
	 * @param {Integer} n    The number of seconds to add
	 */
	
	function addSeconds (date, n) {
		date.setSeconds(date.getSeconds() + n);
	}


	/**
	 * Add hours to a date
	 * @param {Date}    date The date object
	 * @param {Integer} n    The number of hours to add
	 */
	function addHours (date, n) {
		date.setHours(date.getHours() + n);
	}

	/**
	 * Add days to a date
	 * @param {Date}    date The date object
	 * @param {Integer} n    The number of days to add
	 */
	function addDays (date, n) {
		date.setDate(date.getDate() + n);
	}

	/**
	 * Add months to a date
	 * @param {Date}    date The date object
	 * @param {Integer} n    The number of months to add
	 */
	function addMonths (date, n) {
		date.setMonth(date.getMonth() + n);
	}

	/**
	 * Add years to a date
	 * @param {Date}    date The date object
	 * @param {Integer} n    The number of years to add
	 */
	function addYears (date, n) {
		date.setFullYear(date.getFullYear() + n);
	}

