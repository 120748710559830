import React, { useState, useRef } from 'react';
import { Container, Row, Col, FormGroup, Input, InputGroup } from "reactstrap";
import TransportModeDropdown from './TransportMode.js';
// Form removed above
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  Autocomplete
} from "@react-google-maps/api";

//const libraries = ['places'];

function LocationInput(props) {
//const LocationInput = () => {
  /*const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });*/
    // Function to add the current location as an option
    const inputRef = useRef(null);
    const autocompleteRef = useRef(null);
  
    const onLoad = (autocomplete) => {
      autocompleteRef.current = autocomplete;
    };

  
    // Handle selecting the "Use Current Location" option
    const handleCurrentLocationClick = () => {
      console.log('current loc click');
      props.location.locationName = props.currentAddress;

//      onLocationUpdate(index, address);
      inputRef.current.value = props.currentAddress; // Set the current location address in this input

      /*
      if (locationOption) {
        console.log('Current Location locationOption:', locationOption);
        console.log(locationOption.lat);
        console.log(locationOption.lng);

        let thisLocation = reverseGeocode(locationOption);
        console.log('result of reversegeocode');
        console.log(thisLocation);
        // Optionally, reverse geocode here to get an address
      }*/
    };


  //return isLoaded ? (
  return (
  <Container>
      <Row>
      <Col xs={8} sm={8} md={8}>
        <FormGroup>
          <InputGroup className="position-relative">
            <Autocomplete onLoad={onLoad}>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Location"
                name="locationName"
                value={props.location.locationName || ""}
                ref={inputRef}
                onChange={e => props.onChangeLocation(props.index, e)}
                onBlur={e => props.onChangeLocation(props.index, e)}
                style={{ paddingRight: '2.5rem' }} // Add space for the icon
              />
            </Autocomplete>

            {props.currentAddress && (
              <div
              onClick={handleCurrentLocationClick}
              style={{
                position: 'absolute',
                right: '0.75rem',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                backgroundImage: `url("https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png")`,
                backgroundPosition: '0px 0px',
                backgroundSize: '180px 18px',
              }}
              aria-label="Current Location"
            />
            )}
          </InputGroup>
        </FormGroup>
      </Col>        { props.index < 1 ? 

        <Col xs={4} sm={4} md={4}>
          <FormGroup>
            <Input type="datetime-local"
              name="startDateTime" 
              onChange={e => props.onChangeLocation(props.index, e)} 
              defaultValue={props.defaultDate}>
            </Input>
          </FormGroup>
        </Col>
        : 
        <Col xs={4} sm={4} md={4}>
          <TransportModeDropdown
            index={props.index}
            location={props.location}
            onChangeLocation={props.onChangeLocation}
          />
      </Col>

  }
      </Row>
    </Container>
    ) /*   : (
      <></>
    );*/
  
};
export default LocationInput;