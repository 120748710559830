import React, { useEffect } from 'react';

//function LocationFetcher({ props, onLocationUpdate, onAddressUpdate }) {
function LocationFetcher(props) {
  
  useEffect(() => {

    const fetchAddress = async (lat, lng) => {
        try {

            console.log('About to reverse geocode from:');
            console.log('lat: ' + lat);
            console.log('lat: ' + lng);
            const google = window.google;
          
            const geocoder = new google.maps.Geocoder();
            const response = await geocoder.geocode({ location: { lat, lng } });
            //props.location.locationName = response.results[0].formatted_address;
            console.log('Reverse geocode response received: ');
            console.log(response);
//            return response.results[0].formatted_address;
            const address = response.results[0].formatted_address;
            props.onAddressUpdate(address);
/*
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=YOUR_GOOGLE_API_KEY`
          );
          const data = await response.json();
  
          if (data.status === 'OK' && data.results.length > 0) {
            const address = data.results[0].formatted_address;
            onAddressUpdate(address);
          } else {
            console.error('Geocoding failed:', data.status);
            onAddressUpdate('Address not found');
          }*/
        } catch (error) {
          console.error('Error fetching address:', error);
          props.onAddressUpdate('Could not fetch address');
        }
      };
if (!props.currentLocation) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          props.onLocationUpdate({ lat: latitude, lng: longitude });
          fetchAddress(latitude, longitude); // Reverse geocode to get address
        },
        (error) => {
          console.error('Error fetching location:', error);
          props.onLocationUpdate({ error: 'Could not fetch location' });
          props.onAddressUpdate('Location not available or not allowed');        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      props.onLocationUpdate({ error: 'Geolocation not supported' });
      props.onAddressUpdate('Geolocation not supported');
    }
  } else {console.debug('CURRENT LOCATION ALREADY SET ...')}
  }, [props.onLocationUpdate, props.onAddressUpdate]);

  return null; // No UI is needed for this component
}



export default LocationFetcher;
