import { useEffect, useState } from "react";
import LocationInput from "./components/LocationInput/LocationInput.js";
import LocationOutput from "./components/LocationOutput/LocationOutput.js";
import MapOutput from "./components/Maps/MapOutput.js";
import { calcRoutes } from './components/RouteCalculation.js';
//import { useCurrentPosition } from './components/Maps/useCurrentPosition';
import LocationFetcher from './components/Maps/locationFetcher';

import 'bootstrap/dist/css/bootstrap.min.css';
import {getCurrentDateTime, getCurrentTime} from "./utils/TimeFunctions.js";
import {
  useJsApiLoader,
} from "@react-google-maps/api";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
const libraries = ['places'];

//function App() {
const App = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

const [open, setOpen] = useState('1');
const toggle = (id) => {
  if (open === id) {
    setOpen();
  } else {
    setOpen(id);
  }
};

//const { latitude, longitude, error,currentLocation } = useState(useCurrentPosition());
//const { latitude, longitude, error } = useState(useCurrentPosition());
//console.log('latitude: ----- ' + latitude)

const [myLocation, setMyLocation] = useState(null);
const [address, setAddress] = useState('');

const handleLocationUpdate = (newLocation) => {
  setMyLocation(newLocation);
};

const handleAddressUpdate = (newAddress) => {
  setAddress(newAddress);
};

//const {} = 

const [defaultDate, setDefaultDate] = useState(getCurrentTime());

const [locations, setLocations] = useState([{ 
  locationName: "", 
  travelMode : "", 
  travelTime : "", 
  startDateTime : getCurrentDateTime(),
  endDateTime : getCurrentDateTime()
 },
{ locationName: "", 
  travelMode : "DRIVING", 
  travelTime : "", 
  startDateTime : getCurrentDateTime(),
  endDateTime : getCurrentDateTime()
 }
      ])

      const [isCalculated, setIsCalculated] = useState(false);
      const [directions, setDirections] = useState([])
      const [totalDuration, setTotalDuration] = useState(0);
      const [totalDistance, setTotalDistance] = useState(0);
    
      useEffect(() => {
        //    setDefaultDate(moment(defaultDate).format("YYYY-MM-DD[T]HH:mm:ss"));
              setDefaultDate(defaultDate);
          }, []);

      let handleChangeLocation = (i, e) => {
        // NOT USED ???
        console.debug('app - handleChangeLocation');
        console.debug('e.target.name: ' + e.target.name);
        let newLocations = [...locations];
        newLocations[i][e.target.name] = e.target.value;
        setLocations(newLocations);
      }
      let handleChangeStartTime = (i, e) => {
        // NOT USED ???
        console.debug('app - handleChangeStartTime');
        console.debug('e.target.name: ' + e.target.name);

        let newLocations = [...locations];
        newLocations[i][e.target.name] = e.target.value;
        setLocations(newLocations);
      }

      
    let addLocation = () => {
        setLocations([...locations, { locationName: "", travelMode : "DRIVING", travelTime : "", startDateTime : getCurrentDateTime(), endDateTime : getCurrentDateTime() }])
      }
    

    let removeLastLocation = () => {
        removeLocation(locations.length-1);
      }

      let removeLocation = (i) => {
        let newLocations = [...locations];
        newLocations.splice(i, 1);
        setLocations(newLocations)
    }
    
    let refreshLocations = () => {
      setLocations([...locations]);
    }
  

        async function checkRoutes(locations) {
          console.log('app - checkroutes: About to check all routes...');
          let myResult = await calcRoutes(locations);
          console.log(myResult);
          //refreshLocations();
          //setActiveKey('2');
          setIsCalculated(true);
          setOpen('3');
          getDirections(locations);

        }
      
      function getDirections(locations) {
        let listDirections = [];
        let tmpTotalDuration = 0;
        let tmpTotalDistance = 0;
      
        for (let i = 1; i < locations.length; i++) {
          console.log(locations[i]);
          listDirections.push(locations[i].directionsResult);
          tmpTotalDuration += locations[i].travelDuration;
          tmpTotalDistance += locations[i].travelDistance;
      
        }
        setTotalDuration(tmpTotalDuration);
        setTotalDistance(tmpTotalDistance);
        
        setDirections(listDirections);
      }
      
 return isLoaded ? (
  <div>
    <LocationFetcher 
      onLocationUpdate={handleLocationUpdate} 
      onAddressUpdate={handleAddressUpdate}
      currentLocation={myLocation}/>
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">Input</AccordionHeader>
        <AccordionBody accordionId="1">
        {myLocation ? (
    <div style={{ fontSize: '0.9rem', lineHeight: '1.2', padding: '0.5rem' }}>
    <p style={{ margin: '0.2rem 0' }}>
      <strong>Location:</strong> {myLocation.lat}, {myLocation.lng}
    </p>
    <p style={{ margin: '0.2rem 0' }}>
      <strong>Address:</strong> {address || 'Fetching address...'}
    </p>
  </div>          
      ) : (
        <p>Fetching your location...</p>
      )}
        {locations.map((element, index) => (
          <LocationInput                 
              onChangeLocation={handleChangeLocation}
              onChangeStartTime={handleChangeStartTime}
              index={index}
              key={index}
              location={element}
              onRemoveLocation={removeLocation}
              defaultDate={defaultDate}
              currentAddress={address}
        ></LocationInput>
        ))}
        <div className="button-section"> 
            <button className="button add" type="button" onClick={() => addLocation()}>+</button>
            <button className="button remove" type="button" onClick={() => removeLastLocation()}>-</button>
            <button className="button button--shadow" type="button" onClick={() => checkRoutes(locations)}>Get Route</button>
            <button className="button button--shadow" type="button" onClick={() => refreshLocations()}>Refresh</button>
        </div>

        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="2">Output</AccordionHeader>
        <AccordionBody accordionId="2">
        <div className="flex-column">
          
          {totalDistance !== 0 ? <div><h5>Your optimized route:  </h5> 
          <h6>Distance: {Math.round(totalDistance)} KM?</h6>
          <h6>Duration: {Math.round(totalDuration)} MINUTES?</h6>
          <h6>Start Datetime: {locations.length > 0 && locations[0].startDateTime.toLocaleString()} </h6>
          <h6>End Datetime: {locations.length > 0 && locations[locations.length-1].endDateTime.toLocaleString()} </h6>
          </div>
          : <h5>Directions will appear below:</h5> }

          {isCalculated ? <LocationOutput locations={locations}></LocationOutput> : null}

        </div>
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="3">Map</AccordionHeader>
        <AccordionBody accordionId="3">
          <MapOutput
            listDirections={directions}
            currentLocation={myLocation}
          />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  </div>
) : null 
;}

export default App;
