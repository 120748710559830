import React from 'react';
import TransitStepDetails from './TransitStepDetails.js';


function DirectionsSteps({ directionsResult }) {
  // Ensure there's a route to display
  if (!directionsResult || !directionsResult.routes || directionsResult.routes.length === 0) {
    return <div>No route available</div>;
  }

  // Access the first route and its legs (each leg represents a segment between waypoints)
  const route = directionsResult.routes[0];
  const leg = route.legs[0];// Usually we want the first leg if it's a single route
  const steps = leg.steps; 
  


  return (
    <div>
      <h4>Leg:</h4>
      <small>departure: {leg.departure_time ? leg.departure_time.text : null}, arrival: {leg.arrival_time ? leg.arrival_time.text : null}</small>
      <h4>Route Steps:</h4>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        { steps ? steps.map((step, index) => (
          <li key={index} style={{ marginBottom: "1rem" }}>
            <div dangerouslySetInnerHTML={{ __html: step.instructions }} />
            <p><strong>Step {index + 1} - {step.travel_mode}</strong></p>
            <small>
              Distance: {step.distance.text}, Duration: {step.duration.text}
              
              {step.travel_mode === "TRANSIT" ? <TransitStepDetails step={step} index={index}/> : null}
  
            </small>
          </li>
        ))
        : null} 
      </ul>
    </div>
  );
}

export default DirectionsSteps;
/*        const arrivalTimeText = transit.arrival_time?.text || "N/A";
  
        // Other transit line details
        const vehicleType = transit.line.vehicle.type;
        const vehicleName = transit.line.short_name || transit.line.name;
        const agencyName = transit.line.agencies[0]?.name || "N/A";
        const lineColor = transit.line.color || "#000";
        const textColor = transit.line.text_color || "#FFF";*/