import React from 'react';

function TransitStepDetails({ step, index }) {
  // Ensure there's a route to display
  console.log('TransitStepDetails');
        const { transit } = step;
  
        // Retrieve departure time details
        const departureTimeText = transit.departure_time?.text || "N/A";
        const departureTimeValue = transit.departure_time?.value || "N/A"; // Check the actual Date object
        const arrivalTimeText = transit.arrival_time?.text || "N/A";
  
        // Other transit line details
        const vehicleType = transit.line.vehicle.type;
        const vehicleName = transit.line.short_name || transit.line.name;
        const agencyName = transit.line.agencies[0]?.name || "N/A";
        const lineColor = transit.line.color || "#000";
        const textColor = transit.line.text_color || "#FFF";
        console.log('TransitStepDetails - ABOUT TO RETURN DETAILS');

        return (
          <div key={index} style={{ marginBottom: "15px" }}>
            <p>Departure Time (Text): {departureTimeText}</p>
            <p>Departure Time (Exact): {departureTimeValue instanceof Date ? departureTimeValue.toString() : "N/A"}</p>
            <p>Arrival Time: {arrivalTimeText}</p>
            <p>Vehicle: {vehicleType} - {vehicleName}</p>
            <p>Agency: {agencyName}</p>
            <p>Line Color: <span style={{ color: lineColor }}>{lineColor}</span></p>
            <p>Text Color: <span style={{ color: textColor }}>{textColor}</span></p>
            <p>From: {transit.departure_stop.name}</p>
            <p>To: {transit.arrival_stop.name}</p>
          </div>
        );
    

  // ONLY LEG 0 ??? 

  // Access the first route and its legs (each leg represents a segment between waypoints)
 // const route = directionsResult.routes[0];
 // const leg = route.legs[0];// Usually we want the first leg if it's a single route
//  const steps = leg.steps; 
  
// Departure: {step?.transit?.departure_time?.text || "N/A"}
// DepartureValue: {step?.transit?.departure_time?.value || "N/A"}

//      <small>departure: {leg.departure_time ? leg.departure_time.text : null}, arrival: {leg.arrival_time ? leg.arrival_time.text : null}</small>
/*return (
    <div>
    <small>Departure: {step.transit.departure_time?.text || "N/A"}
    DepartureValue: {step.transit.departure_time?.value || "N/A"}
    </small>
    </div>
  );
}
*/
    }
export default TransitStepDetails;
