import React from 'react';


function TransitDetails({ directionsResult }) {
  // Ensure there's a route to display
 if (!directionsResult || !directionsResult.routes || directionsResult.routes.length === 0) {
    return <div>No route available</div>;
  }

  // ONLY LEG 0 ??? 

  // Access the first route and its legs (each leg represents a segment between waypoints)
  const route = directionsResult.routes[0];
  const leg = route.legs[0];// Usually we want the first leg if it's a single route
//  const steps = leg.steps; 
  
// Departure: {step?.transit?.departure_time?.text || "N/A"}
// DepartureValue: {step?.transit?.departure_time?.value || "N/A"}


  return (
    <div>
      <small>departure: {leg.departure_time ? leg.departure_time.text : null}, arrival: {leg.arrival_time ? leg.arrival_time.text : null}</small>
    </div>
  );
}

export default TransitDetails;
