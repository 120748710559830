import { GoogleMap,  Marker, DirectionsRenderer } from '@react-google-maps/api';
//import { useCurrentPosition } from './useCurrentPosition';

function getRandomColor(){
    // "#FF0000"
    let randomColor = Math.floor(Math.random()*16777215).toString(16);
    console.log('randomColor: ' + randomColor);
    randomColor = '#' + randomColor;
    return randomColor;
}

function MapOutput(props) {
  
  console.debug(props.currentLocation)
  //const { latitude, longitude, error } = useCurrentPosition()
  const error = null;
  let latitude;
  let longitude;
  console.debug(latitude);
  let center
  //if (props.currentLocation && (latitude && longitude)) {
    
  if (props.currentLocation && (props.currentLocation && props.currentLocation)) {
      latitude = props.currentLocation.lat;
      longitude = props.currentLocation.lng;
      center = { lat: latitude, lng: longitude }
  } else {
    center = { lat: 51.090739, lng: 4.384737 }
  }
  if (props.listDirections.length > 0) {
    console.log('ABOUT TO RENDER DIRECTIONS');
    console.log(props.listDirections.length);
    console.log(props.listDirections);
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        height: '400px',
        width: '100%'
      }}
      zoom={8}
      center={center}
    >
    {error && <p>{error.message}</p>}
    {latitude && longitude && <Marker position={{ lat: latitude, lng: longitude }} />}
    {props.listDirections.map((direction, index) => (
        <div key={index} className="card">
          <DirectionsRenderer directions={direction} 
                      options={{
                        polylineOptions: {
                          strokeColor: getRandomColor(),
                          strokeOpacity: 0.5,
                          strokeWeight: 4
                        },
                        icon: { scale: 3 }
                      }}
          />
        </div>
      ))}
    
    </GoogleMap>
  )
}
//      apiKey="YOUR_API_KEY"

export default MapOutput;
//                         markerOptions: { icon: blackMarker },
