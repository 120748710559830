//import { response } from "express";
import {addMinutes} from "../utils/TimeFunctions.js";
//addMinutes (date, n)




function computeTotalDistance(lastResult, locationFrom, locationTo) {
    //https://www.positronx.io/how-to-insert-form-values-or-data-in-react-table-component/
    //https://www.geeksforgeeks.org/how-to-pass-data-into-table-from-a-form-using-react-components/
    let totalDistance = 0;
    let time= 0;
    var totalDuration = 0 ;
    let from=0;
    let to=0;
    let arrivalTime;
    let departureTime;
    console.log("computeTotalDistance ---- Calculated Route (lastResult) Object: ");
    console.log(lastResult);

    let myroute = lastResult.routes[0]; // taking first route
    console.log('DUMPING MYROUTE');
    console.log(myroute);
    //let myroute = result;
    for (let i = 0; i < myroute.legs.length; i++) { // in case many waypoints
        totalDistance += myroute.legs[i].distance.value;
  //    debugger;
      time +=myroute.legs[i].duration.text;
      totalDuration +=myroute.legs[i].duration.value;
      from =myroute.legs[i].start_address;
      to = myroute.legs[i].end_address;
      arrivalTime = myroute.legs[i].arrival_time;
     
      departureTime = myroute.legs[i].departure_time;
   
      // zhen multiple legs ?? then will overwrite each other
    }
    time = time.replace('hours','H'); // what if multiple waypoints ?
    time = time.replace('mins','M');
    totalDistance = totalDistance / 1000;
    totalDuration = totalDuration / 60;

    locationTo.travelTime = time;
    locationTo.travelDistance = totalDistance;
    locationTo.travelDuration = totalDuration;
    locationTo.arrivalTime = arrivalTime;
    locationTo.departureTime = departureTime;
    console.log ('RouteCalculation - computeTotalDistance - adding - locationTo Duration' + locationTo.travelDuration + ' to DateTime ' + locationTo.startDateTime);
    getDataType('locationTo.startDateTime',locationTo.startDateTime);
    getDataType('locationTo.travelDuration',locationTo.travelDuration);

    locationTo.endDateTime = addMinutes (locationTo.startDateTime, locationTo.travelDuration);
    console.log('Adding minutes using addMinutes results in: ');
    getDataType('locationTo.endDateTime', locationTo.endDateTime);
    //locations[i+1].startDateTime = locations[i].endDateTime; // a spare/margin number of minutes could be added here

    let resultString = 'RESULT: \r\n';
    resultString+='from: ' + from + '\r\n';
    resultString+='to: ' + to + '\r\n';
    resultString+='depart at: ' + lastResult.request.drivingOptions.departureTime + '\r\n';
    resultString+='origin: ' + lastResult.request.origin;
    resultString+='destination: ' + lastResult.request.destination;
    resultString+='mode: ' + lastResult.request.travelMode + '\r\n';
    resultString+='time: ' + time + '\r\n';
    resultString+='totalDistance: ' + totalDistance + '\r\n';
    resultString+='totalDuration: ' + totalDuration + '\r\n';
    console.log(resultString);
    //alert(resultString);

}

function getMode (locationMode)  {
    const google = window.google;

    switch(locationMode) {
        case 'DRIVING':
          return google.maps.TravelMode.DRIVING;
        case 'WALKING':
            return google.maps.TravelMode.WALKING;
        case 'BICYCLING':
            return google.maps.TravelMode.BICYCLING;
        case 'TRANSIT':
            return google.maps.TravelMode.TRANSIT;
        default:
            return google.maps.TravelMode.DRIVING;
        }
}

function getDataType(field, value) {
  console.log('GETTING TYPE for field:' + field);
  console.log('GETTING TYPE for value:' + value);
  console.log(typeof value);

}

//async function calcSingleRoute(locationFrom, locationTo, myTravelMode, location) {
async function calcSingleRoute(locationFrom, locationTo, stepNumber) {
    console.log('RouteCalculation - calcSingleRoute - CALCULATE STEP: ' + stepNumber);

    // if at least second step and thus endDatetime of initial step assumed to be known
    if (stepNumber > 0) locationTo.startDateTime = locationFrom.endDateTime;

    const google = window.google;
    let directionsService = new google.maps.DirectionsService();
//    calcSingleRoute(locations[i], locations[i+1]    
//    calcSingleRoute(locations[i].locationName, locations[i+1].locationName, locations[i+1].travelMode, locations[i+1]);
    let myOrigin = locationFrom.locationName;
    let myDestination = locationTo.locationName;
    let myTravelMode = locationTo.travelMode;
    let myCurrentTime = new Date(Date.now());
    let myStartTime = locationTo.startDateTime;
    console.log('DATATYPES -----------');
    getDataType('myCurrentTime',myCurrentTime);
    getDataType('myStartTime',myStartTime);
    getDataType('locationTo.startDateTime',locationTo.startDateTime);
    getDataType('locationTo.endDateTime',locationTo.endDateTime);
  
    
    console.log('calcSingleRoute - POTENTIAL NEW START TIME: ' + locationTo.startDateTime);
    console.log('USING myStartTime: ' + myStartTime);
// DEPARTURE TIME CHECK
// https://stackoverflow.com/questions/23354918/google-maps-transit-departure-time-not-working-properly
console.log('OVERRIDING departureTime: new Date("2024-11-22T17:20:00"): ' + new Date("2024-11-22T17:20:00"));
    const request = {
        origin: myOrigin,
        destination: myDestination,
        travelMode: getMode(myTravelMode),
        drivingOptions: {
            departureTime: myStartTime, // new Date("2024-11-11T09:00:00") //new Date(Date.now()), //option later to modify start time
            trafficModel: 'bestguess'
        },
        transitOptions: {
            departureTime: myStartTime,
        },
        unitSystem: google.maps.UnitSystem.METRIC,
        //provideRouteAlternatives: true,
        //avoidFerries: true,
        avoidHighways: false,
        avoidTolls: false,
        //region: String
};

/*
origin: origin,
destination: destination,
travelMode: window.google.maps.TravelMode.TRANSIT,
transitOptions: {
  departureTime: departureDateTime,
},
*/

console.log('calcSingleRoute - about to request directions');
console.log(request);

await directionsService.route(request, function(response, status) {
  if (status !== 'OK') {
    console.log('app.js directionsService returns ERROR');
    return null;
  } else {
    console.log('app.js directionsService returns OK');
    //console.log(response);
    computeTotalDistance(response, locationFrom, locationTo);
    locationTo.directionsResult = response;
    return response;

    //setDirectionsResult(response.routes[0].legs);
  };
});

}
export async function calcRoutes(locations) {
    console.log('RouteCalculation - CalcRoutes - MAIN ROUTINE')
//  javaScriptRelease = Date.parse('04 Dec 1995 00:12:00 GMT')
    let now = new Date(locations[0].startDateTime);
//    now = Date.parse(locations[0].startDateTime);
    locations[1].startDateTime = now;
    await calcRoutesLocal(locations);
    //await used so render at end
    console.log('RENDERING RESULTS ............');
    //addResults(locations);
    return "RETURNSTRING RETURN OBJECT WITH SUMMARY TOTAL DISTANCE + TOTALTIME within object 'resultSummary' ";
}
async function calcRoutesLocal(locations) {
    console.log('calcRoutesLocal - START');
    console.log('calcRoutesLocal - START - Number of Routes: ' + locations.length );
    console.table(locations);
//    console.log(JSON.stringify(locations));

//    calcSingleRoute(locations[0].locationName, locations[1].locationName, locations[1].travelMode)
//    let directionsResponse;

    for (let i = 0; i < locations.length - 1; i++) {
        console.log('RouteCalculation - calRoutesLocal - WILL CALCULATE STEP: ' + i);
        //directionsResponse = 
        await calcSingleRoute(locations[i], locations[i+1], i);
        //console.log('CALCULATED: ' + i);
        //console.log(directionsResponse);
        //locations[i+1].directionsResult = directionsResponse;
        //directionsResponse = await calcSingleRoute(locations[i].locationName, locations[i+1].locationName, locations[i+1].travelMode, locations[i+1]);
        //computeTotalDistance(directionsResponse); enriching location was executed in directionsService callback
    }

    console.log('AFTER PROCESSIONG LOCATIONS : ');
    console.table(locations);

    return (
        <div>
        SOME TEXT        
        </div>
      );


  }