import { Fragment, useState } from "react";
//import React, { Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {getTimeString} from "../../utils/TimeFunctions.js";
import DirectionsSteps from './DirectionsSteps.js';
import TransitDetails from './TransitDetails.js';


/*
function hyperlinkDirection() {
  // WARNING no start time possible here
  // https://developers.google.com/maps/documentation/urls/get-started#directions-action
//  https://www.google.com/maps/dir/?api=1&origin=Google+Pyrmont+NSW&destination=QVB&destination_place_id=ChIJISz8NjyuEmsRFTQ9Iw7Ear8&travelmode=walking
  let myHyperlink = 'https://www.google.com/maps/dir/?api=1&origin=Google+Pyrmont+NSW&destination=QVB&destination_place_id=ChIJISz8NjyuEmsRFTQ9Iw7Ear8&travelmode=walking'
  return myHyperlink;
}
*/

//: {trip.travelDistance}
function LocationOutput(props) {
    const [locationNum, setLocationNum] = useState(1);
/*
    const [totalDuration, setTotalDuration] = useState(0);
    const [totalDistance, setTotalDistance] = useState(0);

//    let handleChangeLocation = (i, e) => {

    const getTotals = (locations) => {
      let tmpTotalDuration = 0;
      let tmpTotalDistance = 0;
    
      for (let i = 1; i < locations.length; i++) {
        console.log(locations[i]);
        tmpTotalDuration += locations[i].travelDuration;
        tmpTotalDistance += locations[i].travelDistance;
      }
      
      setTotalDuration(tmpTotalDuration);
      setTotalDistance(tmpTotalDistance);
    }
    
getTotals(props.locations);
   */
    
return (
    <Fragment>

<h5>Click on a leg of your route to show directions:</h5>
<ol className="leg--list">
<ol>

{props.locations.length > 0 ? props.locations.map((trip, i) => {
      if (i>0) 
      return (<li key = {trip.locationName} className="leg--button button--shadow"
         onClick={() => setLocationNum(i)}>
           { trip.travelMode} : {props.locations[i-1].locationName} => { trip.locationName} at {getTimeString(trip.endDateTime)} 
         </li>)
      else return null;
    }) : null}
</ol> 

</ol> 

<h6>Directions:</h6>
<div>
<p>Start: {props.locations.length > 0 ? "Trip: " + locationNum : null }</p>
  {props.locations[locationNum].travelTime = 'TRANSIT' ? <TransitDetails directionsResult={props.locations[locationNum].directionsResult} /> : null}
  <p>From: {locationNum > 0 ?  " " + props.locations[locationNum-1].locationName : null}</p> 
  <p>End: {locationNum > 0 ?  " " + props.locations[locationNum].locationName : null}</p> 
  <p>StartTime: {locationNum > 0 ? " " + props.locations[locationNum].startDateTime : null}</p> 
  <p>EndTime: {locationNum > 0 ? " " + props.locations[locationNum].endDateTime : null}</p> 
  <p>TravelTime: {locationNum > 0 ? " " + props.locations[locationNum].travelTime : null}</p> 
  <p>TravelDuration: {locationNum > 0 ? " " + props.locations[locationNum].travelDuration : null}</p> 
  <p>TravelDistance: {locationNum > 0 ? " " + props.locations[locationNum].travelDistance : null}</p> 
  <DirectionsSteps directionsResult={props.locations[locationNum].directionsResult} /> 
  <p>DUMP:</p>
  {JSON.stringify(props.locations[locationNum].directionsResult)} 
</div>
</Fragment>
);

}
export default LocationOutput;
//<!-- <DirectionsSteps directionsResult={props.locations[locationNum].directionsResult} /> -->


// FIRST LIST
//               <li key = {leg.locationName} className="leg--button button--shadow" onClick={() => setLocationNum(i)}>{leg.locationName} --> {locations[i+1].locationName} ({leg.travelTime})</li>
//                <li key={i}> {locations[i].locationName}</li>

// BETWEEN START END - render specific directionsResult test : between the 2 p's
/*
<ol>

{locations.length > 0 ? locations[locationNum].steps.map(step => (
    <li key={step.start_location + step.distance}>{step.instructions.replace(/<[^>]*>?/gm, '')} ({step.distance.text})</li>
)) : null}
</ol> 
*/
/* selected step
          <h6>Directions:</h6>
          <div>
            <p>Start: {locations.length > 0 ? locations[locationNum].startDateTime : null }</p>
            <p>End: {locations.length > 0 ? locations[locationNum].endDateTime : null}</p> 
          </div>

*/
