import React, { useState, useEffect } from 'react';
import { InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const transportModes = [
  { name: 'Transit', value: 'TRANSIT', icon: 'https://maps.gstatic.com/consumer/images/icons/2x/directions_transit_grey800_24dp.png' },
  { name: 'Driving', value: 'DRIVING', icon: 'https://maps.gstatic.com/consumer/images/icons/2x/directions_car_grey800_24dp.png' },
  { name: 'Bicycling', value: 'BICYCLING', icon: 'https://maps.gstatic.com/consumer/images/icons/2x/directions_bike_grey800_24dp.png' },
  { name: 'Walking', value: 'WALKING', icon: 'https://maps.gstatic.com/consumer/images/icons/2x/directions_walk_grey800_24dp.png' },
];

function TransportModeDropdown({ index, location, onChangeLocation }) {
  const initialMode = transportModes.find((mode) => mode.value === location.travelMode) || transportModes[0];
  const [selectedMode, setSelectedMode] = useState(initialMode);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hasSelected, setHasSelected] = useState(false); // Flag to disable toggle after selection

  // Toggle the dropdown only if a selection hasn't been made
  const toggleDropdown = () => {
    if (!hasSelected) setDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const newMode = transportModes.find((mode) => mode.value === location.travelMode);
    if (newMode && newMode.value !== selectedMode.value) {
      setSelectedMode(newMode);
    }
  }, [location.travelMode]);

  const handleSelect = (mode) => {
    console.debug('TransportModeDropdown - handleSelect - mode: ' + mode);
    setSelectedMode(mode);
    setHasSelected(true); // Prevent dropdown from opening after selection
    setDropdownOpen(false); // Close the dropdown
    onChangeLocation(index, { target: { name: 'travelMode', value: mode.value } });
  };

  return (
    <InputGroup>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} addonType="append">
        <DropdownToggle caret color="secondary" style={{ padding: '5px' }}>
          <img src={selectedMode.icon} alt={selectedMode.name} style={{ width: 24, height: 24 }} />
        </DropdownToggle>
        <DropdownMenu>
          {transportModes.map((mode) => (
            <DropdownItem key={mode.value} onClick={() => handleSelect(mode)}>
              <img src={mode.icon} alt={mode.name} style={{ width: 24, height: 24 }} />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </InputGroup>
  );
}

export default TransportModeDropdown;
